import * as React from 'react'
import Seo from '../seo'
import Footer from '../components/footer'
import {useEffect} from "react";
import asyncAnalytics from "../components/analytics";

const content = [
  {
    title: <h4>Introdução</h4>,
    body: (
      <div>
        <p>
          Por meio desta Política de Privacidade, nós, da <span className="bold">Dinerama Ltda.</span>, pessoa jurídica
          de direito privado inscrita
          no CNPJ/ME sob o nº 44.643.790/0001-07, com sede na Avenida Santo Amaro, 3.131 – cj. 1813, no bairro Brooklin
          Paulista da cidade de São Paulo/SP, CEP 04555-000 (“<u>Dinerama</u>” ou “<u>nós</u>”) buscamos trazer transparência e
          demonstrar aos usuários da plataforma Dinerama (“Usuários” e “Plataforma”, respectivamente) e ao público em
          geral nosso compromisso com a privacidade, a proteção e a segurança dos dados pessoais tratados em nossa
          operação – em especial porque seu tratamento é condição necessária para que possamos prestar nossos serviços.
        </p>
        <p>
          Por essa razão, sugerimos uma leitura atenta e completa desta Política de Privacidade, bem como, aos Usuários,
          dos Termos de Uso da Plataforma, em especial levando em conta que a compreensão das e a concordância às
          disposições de ambos os documentos é condição essencial ao cadastro e à utilização da Plataforma.
        </p>

        <div className="box">
          <p>NOTA ESPECIAL PARA CRIANÇAS E ADOLESCENTES (PESSOAS COM IDADE INFERIOR A 18 ANOS)</p>
          <p>Os serviços disponibilizados pela Dinerama envolvem aspectos financeiros. Se você não possui, ainda, 18
            (dezoito) anos completos, por favor, certifique- se de fazer uso dos serviços com o monitoramento e
            acompanhamento de seu/sua responsável legal.</p>
        </div>
      </div>
    )
  },
  {
    title: <h4>1. A quem se destina esta Política?</h4>,
    body: (
      <div>
        <p>1.1. A Política de Privacidade da Dinerama se destina a todos que visitam nosso site, aos usuários que fazem
          uso
          de nossa Plataforma e a todos aqueles que, de alguma forma, interagem com a Dinerama.</p>
      </div>
    )
  },
  {
    title: <h4>2. Quais dados pessoais tratamos?</h4>,
    body: <div>
      <p>
        2.1. Além dos dados necessários para o correto e fiel cumprimento das obrigações da Dinerama perante o Usuário
        e para viabilizar o uso da Plataforma, submetidos voluntariamente pelo Usuário, a Dinerama pode, ainda, coletar
        dados adicionais durante a interação do Usuário com a Plataforma. De modo geral, os dados pessoais tratados pela
        Dinerama incluem:
      </p>

      <table>
        <thead>
        <tr>
          <th>Tipos de dados</th>
          <th>Dados Pessoais</th>
          <th>Finalidade de Uso dos Dados Pessoais</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td className="bold">Cadastrais</td>
          <td>
            <ul>
              <li>Nome completo</li>
              <li>E-mail</li>
              <li>CPF</li>
              <li>Data de Nascimento</li>
              <li>Número de telefone</li>
              <li>Foto do perfil</li>
              <li>Foto e foto do documento</li>
              <li>Gênero</li>
            </ul>
          </td>
          <td>
            <ol>
              <li>Identificar o Usuário</li>
              <li>Cumprir as obrigações assumidas pela Dinerama por meio da Plataforma (inclusive de suporte)</li>
              <li>Cumprir com obrigações legais e regulatórias</li>
              <li>Viabilizar a efetivação dos serviços disponíveis na Plataforma (resgate do cashback)</li>
              <li>Promover produtos e serviços da Dinerama</li>
              <li>Aprimorar a Plataforma e a experiência do Usuário</li>
              <li>Detectar e prevenir fraudes ou violações aos Termos de Uso da Plataforma</li>
              <li>Informar sobre novidades, funcionalidades, conteúdos, notícias e demais eventos relevantes para a
                manutenção do relacionamento com o Usuário
              </li>
              <li>Elaborar análises e estudos estatísticos</li>
            </ol>
          </td>
        </tr>
        <tr>
          <td className="bold">Dados financeiros</td>
          <td>
            <ul>
              <li>Chave Pix</li>
            </ul>
          </td>
          <td>
            <ol>
              <li>Viabilizar a efetivação dos serviços disponíveis na Plataforma (resgate do cashback)</li>
              <li>Detectar e prevenir fraudes</li>
              <li>Elaborar análises e estudos estatísticos</li>
            </ol>
          </td>
        </tr>
        <tr>
          <td className="bold">Dados históricos</td>
          <td>
            <ul>
              <li>Histórico de buscas</li>
              <li>Histórico de ofertas ativadas</li>
              <li>Histórico de compras</li>
              <li>Histórico de resgates de cashback</li>
            </ul>
          </td>
          <td>
            <ol>
              <li>Viabilizar a efetivação dos serviços disponíveis na Plataforma (resgate do cashback)</li>
              <li>Aprimorar a Plataforma e a experiência do Usuário</li>
              <li>Elaborar análises e estudos estatísticos</li>
            </ol>
          </td>
        </tr>
        <tr>
          <td className="bold">Dados de identificação digital</td>
          <td>
            <ul>
              <li>ID de usuário (atribuído pela Plataforma)</li>
              <li>Endereço IP e Porta Lógica de Origem</li>
              <li>Registros de interações com a Plataforma e com a Dinerama</li>
              <li>Telas acessadas</li>
              <li>Dispositivo (marca e modelo, identificação do dispositivo, versão do sistema operacional e
                preferências)
              </li>
              <li>Geolocalização (caso autorizada)</li>
              <li>Session ID</li>
              <li>Tokens</li>
              <li>Cookies</li>
            </ul>
          </td>
          <td>
            <ol>
              <li>Identificar o Usuário</li>
              <li>Cumprir as obrigações assumidas pela Dinerama por meio da Plataforma</li>
              <li>Cumprir com obrigações legais e regulatórias</li>
              <li>Detectar e prevenir fraudes ou violações aos Termos de Uso da Plataforma</li>
              <li>Aprimorar a Plataforma e a experiência do Usuário</li>
              <li>Elaborar análises e estudos estatísticos</li>
            </ol>
          </td>
        </tr>
        </tbody>
      </table>

      <p>2.2. A Dinerama não tem a intenção de tratar dados pessoais sensíveis; porém é importante que o Usuário saiba
        que os produtos adquiridos, inclusive em supermercados, farmácias e lojas de conveniência, são listados nas
        Notas Fiscais que são enviadas por meio da Plataforma para as finalidades de cálculo do cashback, o que foge do
        controle da Dinerama.</p>
      <ol>
        <li>A Dinerama não utilizará essas informações para nenhum outro propósito que não seja disponibilizar o
          cashback ao Usuário.
        </li>
        <li>Caso o Usuário não deseje compartilhar informações acerca de qualquer produto que conste da Nota Fiscal
          emitida, pedimos que não realize qualquer solicitação de cashback relacionada à referida Nota Fiscal pela
          Plataforma.
        </li>
      </ol>
      <p>2.3. O Usuário pode optar por autenticar-se utilizando sua biometria, para o que deverá cadastrá-la utilizando
        o sistema operacional de seu aparelho móvel (iOS ou Android), o qual será empregado em tal autenticação e,
        portanto, a Dinerama não tratará os dados biométricos do Usuário, recebendo apenas o hash da senha de acesso,
        que não permite à Dinerama ter acesso aos dados pessoais sensíveis do Usuário.</p>
      <p>2.4. A Dinerama não é responsável pela precisão ou pela veracidade ou falta dela nas informações prestadas pelo
        Usuário ou, ainda, por sua desatualização, sendo de responsabilidade do Usuário prestá-las com exatidão ou
        atualizá-las se o caso.</p>
      <p>2.5. A base de dados formada por meio da coleta de dados pessoais na Plataforma é de propriedade e
        responsabilidade da Dinerama, sendo que seu uso, acesso e compartilhamento, quando necessários, serão feitos
        dentro dos limites e propósitos dos negócios da Dinerama e descritos nesta Política de Privacidade.</p>
      <ol>
        <li>Internamente, os dados dos Usuários são acessados somente por profissionais devidamente autorizados pela
          Dinerama, respeitando os princípios de proporcionalidade, necessidade e relevância para os objetivos da
          Dinerama, além do compromisso de confidencialidade e preservação da privacidade nos termos da Política de
          Privacidade.
        </li>
        <li>O Usuário é corresponsável pelo sigilo de seus dados pessoais e, reiterando, o compartilhamento de suas
          credenciais de acesso (login e senha) viola os Termos de Uso da Plataforma.
        </li>
      </ol>
    </div>,
  },
  {
    title: <h4>3. Com quem compartilhamos dados pessoais?</h4>,
    body: (
      <div>
        <p>3.1. A Dinerama valoriza e respeita a privacidade do Usuário, e se compromete a não compartilhar seus dados
          pessoais com terceiros, salvo se em uma das seguintes circunstâncias:</p>
        <ol>
          <li>Com os Parceiros da Dinerama, anunciantes das campanhas de cashback disponibilizadas na Plataforma;</li>
          <li>Se expressamente autorizado pelo Usuário, quando necessário e/ou aplicável;</li>
          <li>Para processamento externo por organizações ou empresas que atuem por conta e ordem e em nome da Dinerama
            no tratamento de dados pessoais, como empresas que auxiliam no atendimento ou no marketing da Dinerama,
            hipótese em que serão sujeitos sob rígidas instruções e sob dever de confidencialidade e segurança;
          </li>
          <li>Quando necessário para a operação da Dinerama, como na contratação de serviços de hospedagem de bancos de
            dados, backups, ou afins;
          </li>
          <li>Em consultas a empresas terceiras e órgãos setoriais a fim de validar a identidade do Usuário com o
            objetivo de prevenir fraudes e combater a lavagem de dinheiro e o financiamento do terrorismo;
          </li>
          <li>Com autoridades e órgãos governamentais, quando visando o cumprimento de qualquer legislação,
            regulamentação, decisão judicial ou solicitação governamental, ou ainda em razão de detecção de fraudes,
            ocasião em que a Dinerama irá compartilhar o mínimo de informações necessárias para atingir a sua
            finalidade; e/ou
          </li>
          <li>Em caso de reestruturação societária, alteração de controle e/ou transferência de ativos, hipótese em que
            a Dinerama poderá compartilhar ou mesmo transferir as informações e os dados pessoais do Usuário com ou para
            as pessoas físicas ou jurídicas envolvidas.
          </li>
        </ol>
        <p>3.2. Em qualquer hipótese, a Dinerama poderá utilizar e/ou compartilhar dados com outros não listados acima de
          forma anonimizada, já que, nessa condição, referidos dados perdem sua característica de dados pessoais.</p>
      </div>
    )
  },
  {
    title: <h4>4. Por quanto tempo armazenamos seus dados pessoais?</h4>,
    body: (
      <div>
        <p>4.1. Os dados pessoais coletados e os registros de atividades estarão armazenados em ambiente seguro e
          controlado, observado o estado da técnica disponível, pelo prazo exigido por lei, considerando:</p>
        <ol>
          <li>O prazo mínimo de 6 (seis) meses para o armazenamento do registro das atividades, nos termos do Marco
            Civil da Internet;
          </li>
          <li>O prazo mínimo de 5 (cinco) anos para armazenamento de dados referentes às operações de cashback, nos
            termos do Código de Defesa do Consumidor e demais regras aplicáveis; e
          </li>
          <li>O prazo máximo de 5 (cinco) anos para o armazenamento dos dados pessoais, contados do cancelamento da
            conta de acesso à Plataforma, nos termos do Código de Defesa do Consumidor.
          </li>
        </ol>
        <p>4.2. No entanto, pode ocorrer de os dados precisarem ser mantidos por período superior, em casos de
          auditoria, por motivo de ordem judicial ou visando a preservação de direitos da Dinerama. Em qualquer
          hipótese, ao término do prazo aplicável, os dados pessoais tratados pela Dinerama serão excluídos com uso de
          métodos de descarte seguro, podendo, em qualquer hipótese e a qualquer tempo, serem utilizados de forma
          anonimizada para fins estatísticos.</p>
        <div className="box">
          <p>4.3. Os dados pessoais tratados pela Dinerama são armazenados em ambiente de uso de recursos ou servidores
            na nuvem (cloud computing), contratado junto à Alphabet, Inc. (Google Cloud), o que enseja, neste último
            caso, transferência ou processamento dos dados fora do Brasil.</p>
        </div>
      </div>
    )
  },
  {
    title: <h4>5. Quais são os direitos dos titulares perante a Dinerama?</h4>,
    body: (
      <div>
        <p>5.1. Sem prejuízo das regras específicas aplicáveis aos Usuários, tais quais dispostas nos Termos de Uso da
          Plataforma, os titulares de dados pessoais poderão requerer à Dinerama, pelos canais de atendimento
          disponibilizados abaixo:</p>
        <ol>
          <li>a exibição de seus dados pessoais armazenados pela Dinerama;</li>
          <li>a retificação de dados pessoais incorretos ou desatualizados;</li>
          <li>a anonimização, o bloqueio ou a eliminação de dados pessoais que sejam considerados desnecessários ou
            excessivos ou que estejam sendo tratados em desconformidade com a Lei Geral de Proteção de Dados;
          </li>
          <li>a revogação de seu consentimento e/ou a eliminação de dados pessoais tratados com base no consentimento do
            titular, caso e quando aplicável;
          </li>
          <li>a eliminação de dados pessoais armazenados pela Dinerama, caso superado o prazo previsto no item 4.1.3
            acima, quando não realizada de forma automática pela Dinerama; e
          </li>
          <li>a indicação das entidades públicas e privadas com as quais a Dinerama compartilhou seus dados pessoais.
          </li>
        </ol>
        <p>5.2 A Dinerama fará sempre o possível para atender às solicitações dos titulares em um prazo menor do que 15
          (quinze) dias, o qual poderá ser superado em caso de dificuldades técnicas ou operacionais.</p>
        <p>Os titulares poderão acionar o Encarregado (DPO) da Dinerama, visando o atendimento a qualquer dos itens
          acima, por meio do endereço de e-mail bruna@dinerama.com.br.</p>
      </div>
    )
  },
  {
    title: <h4>6. Disposições Gerais</h4>,
    body: (
      <div>
        <p>6.1. A Dinerama se reserva o direito de alterar o teor desta Política de Privacidade a qualquer momento,
          conforme a finalidade ou necessidade, tal qual para adequação e conformidade legal de disposição de lei ou
          norma que tenha força jurídica equivalente, desde que a mantenha em conformidade com a legislação brasileira,
          em especial a Lei nº 13.709 de 2018 (Lei de Proteção de Dados Pessoais) e a Lei Federal nº 12.965 de 2014
          (Marco Civil da Internet), cabendo ao Usuário verificá-la sempre que acessar o site da Dinerama, a Plataforma
          ou que, de outra forma, interagir com a Dinerama.</p>
        <p>6.2. Ocorrendo atualizações substanciais neste documento, em especial que gerem impacto relevante ao Usuário,
          a Dinerama irá notificar os Usuários pelos meios de contato por eles fornecidos.</p>
        <p>6.3. Em caso de dúvidas com relação às disposições constantes desta Política de Privacidade, o Usuário poderá
          entrar em contato com o Encarregado (DPO) da Dinerama pelo meio indicado no item 5.3 acima.</p>
        <p>6.4. Caso empresas terceirizadas realizem o processamento de quaisquer dados coletados pela Dinerama, deverão
          respeitar as condições aqui estipuladas e as normas de Segurança da Informação da Dinerama, obrigatoriamente,
          e se sujeitarão a compromissos específicos de confidencialidade e de respeito à privacidade e à proteção de
          dados pessoais dos Usuários.</p>
        <p>6.5. Caso alguma disposição destas Política de Privacidade seja considerada ilegal ou ilegítima por
          autoridade da localidade em que o Usuário resida ou da sua conexão à internet, as demais condições
          permanecerão em pleno vigor e efeito. </p>
        <p>6.6 Todos os registros e guarda de dados realizados no ambiente da Plataforma serão realizados e catalogados
          com base no horário oficial de Brasília.</p>
      </div>
    )
  },
]

export default function PrivacyPolicy(props) {
  useEffect(() => {
    asyncAnalytics(props)
  }, [props]);

  return (
    <div id="privacy-policy-root">
      <Seo title="Dinerama"/>
      <div className="header">
        <a href="https://www.dinerama.com.br">
          <img src="/images/logo-name.svg" alt="dinerama"/>
        </a>
      </div>
      <div className="privacy-policy">
        <h2>Política de Privacidade</h2>
        <h4 className="update-date">Data da Última Atualização: 23 de Agosto de 2022</h4>
        <div className="content">
          {content.map((c) => (
            <div>
              {c.title}
              {c.body}
            </div>
          ))
          }
        </div>
      </div>
      <Footer/>
    </div>
  )
}
