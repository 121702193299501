import React, {useState, useEffect} from 'react'
import MailchimpSubscribe from 'react-mailchimp-subscribe'

const CustomForm = ({ status, message, onValidated }) => {

    const [email, setEmail] = useState('');
    const [labelMessage, setLabelMessage] = useState('');

    useEffect(() => {
        if(status === "success") {
            clearFields();
            setLabelMessage('Cadastrado!')
        }

        if(status === "error") {
            if(message.match('is already subscribed to list')) {
                setLabelMessage('Este e-mail já foi cadastrado na nossa lista.')
            } else {
                setLabelMessage('Tivemos um erro inesperado. Tente novamente mais tarde.')
            }
        }
      }, [status])
    
    const clearFields = () => {
        setEmail('');
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        email &&
        email.indexOf("@") > -1 &&
        onValidated({
            EMAIL: email,
        });
    }

    const validateInput = (email: string) => 
        ((email === '') || email.indexOf('@') === -1)

    return (
        <>
            <form className='mail-list-form' onSubmit={(e) => handleSubmit(e)}>
                <input
                    onChange={(e) => setEmail(e.target.value)}
                    type='email'
                    placeholder='Insira o seu e-mail'
                    value={email}
                    className='email-input'
                    name='email'
                    required
                />

                <input
                    className='submit-button'
                    type='submit'
                    value=''
                    disabled={validateInput(email)}
                />
            </form>
            <p className="message">
                {labelMessage}
            </p>
        </>
    )
}

const MailchimpFormContainer = () => {
    const postUrl = `https://gmail.us10.list-manage.com/subscribe/post?u=52600aadc5090444b965fcdd6&id=5de6db2d0c`;

    return (
        <div className=''>
            <MailchimpSubscribe 
                url={postUrl}
                render={({ subscribe, status, message }) => (
                    <CustomForm
                        status={status}
                        message={message}
                        onValidated={formData => subscribe(formData)}
                    />
                )}
            />
        </div>
    )
}

export default MailchimpFormContainer