import React from 'react'
import MailchimpFormContainer from './mail-chimp-container'
import {StaticImage} from "gatsby-plugin-image";
import {Link} from "gatsby";

export default function Footer() {
  return (
    <div className="footer">
      <div className="information">
        <a href="http://www.dinerama.com.br">
          <img src="/images/logo-name_.svg" alt="dinerama"></img>
        </a>
        <div className="disclaimer">
          <p>Colocamos o poder de escolha nas suas mãos. Com a Dinerama, ganhar dinheiro de volta nas compras do dia a dia nunca foi tão simples.</p>
        </div>
        <div className="privacy">
          <p className="privacy-links">
            <Link to="/termos-de-uso/">Termos de uso</Link> e <Link to="/politica-de-privacidade/">Política de Privacidade</Link>
          </p>
        </div>
      </div>
      <div className="contact">
        <div className="mail-list">
          <h5>Receba nossas novidades</h5>
          <MailchimpFormContainer />
        </div>
      </div>
      <div className="social">
        <p className="title">Acompanhe nossas redes</p>
        <div className="social-network-list">
          <a href="https://www.facebook.com/Dinerama-102363555328314" target="_blank">
            <StaticImage
              src="../../static/images/facebook.svg"
              alt="facebook link"
              quality="100"
              objectFit="contain" />
          </a>
          <a href="https://www.linkedin.com/company/dineramaapp" target="_blank">
            <StaticImage
              src="../../static/images/linkedin.svg"
              alt="linkedin link"
              quality="100"
              objectFit="contain" />
          </a>
          <a href="https://www.instagram.com/dineramaapp/" target="_blank">
            <StaticImage
              src="../../static/images/instagram.svg"
              alt="instagram link"
              quality="100"
              objectFit="contain" />
          </a>
        </div>
      </div>
    </div>
  )
}
